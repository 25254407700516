import { apiSlice } from "./apiSelice"

export const WorkoutSelice = apiSlice.injectEndpoints({
    
    endpoints: builder => ({
        
       GetWorkout:builder.query({
        query:(a)=>'/workouts?page=1&paginate=100',
            providesTags:['Workout']
       }),
       UpateWorkout:builder.mutation({
        query:({...rest})=>({
       
              url:`/workouts/${rest.id}`,
              method:'PATCH',
              body:rest,
          
            
        }),
        invalidatesTags: ['Workout'],

 }),
 
    PostWorkout:builder.mutation({
    
    query:(formdata)=>({
          url:`/workouts`,
          header:{
            'Content-Type':`multipart/form-data`,          
          },
          method:'POST',
          body:formdata,
       
    }

    ),
    
    invalidatesTags: ['Workout'],

})
    })
})

export const {
    useGetWorkoutQuery,
    useUpateWorkoutMutation,
    usePostWorkoutMutation

} = WorkoutSelice