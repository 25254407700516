// import { IconButton } from '@mui/material';
// import { AndroidPushNotificationPreview, ApplePushNotificationPreview, PushNotificationPreview } from "push-notification-preview";

import React, { useEffect, useState } from "react";
import Loading from "../../components/lodaing/Loading";

import {
  Button,
  Switch,
} from "@mui/material";
import { swal } from "../../components/swal";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { BiDetail, BiSave } from "react-icons/bi";
import {  useGetProductsQuery,
  useUpateProductsMutation, } from "../../app/Selice/FollowupSelice";
import { Link } from "react-router-dom";
import { useGetMealsQuery } from "../../app/Selice/MealsSelice";
import { useGetWorkoutQuery } from "../../app/Selice/WorkOutSelice";



function Workouts() {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const { data, isLoading, isFetching, isSuccess } =
  useGetWorkoutQuery(page,pageSize);
   
  const themeColor = useSelector((state) => state.theme.value);
  const [loading, isloading] = useState(0);
 console.log(data)
 var columns =[]
 data?.meta?.columns?.map((item)=>{
  columns.push({
    field: item, headerName: t(item),   flex:1 
  })
 })

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flexsx" style={{ width: "75vw" }}>
        <DataGrid
      style={{height:"80vh",width:"75vw",margin:'20px',color:themeColor.text}}
        rows={data?data.data:[]}
        columns={columns}
       pageSize={pageSize}
          onPageSizeChange={
            (newPageSize) => setPageSize(newPageSize)
          }
  onPageChange={(newPage) => setPage(page+1)}
          rowsPerPageOptions={[10,25,50,100]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
        </div>
      )}
     
    </>
  );


}

export default Workouts;
