import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import { useTranslation } from "react-i18next";
import { swal } from "../../components/swal";
import {usePostWorkoutMutation } from "../../app/Selice/WorkOutSelice";

const NewWorkout = () => {
  const themeColor = useSelector((state) => state.theme.value);
  const [PostWorkout, error] = usePostWorkoutMutation();
  var formdata = new FormData();
  const [files, setFiles] = useState([]);
  function handleChange(e) {
    const files = e.target.files;
    const allFiles = [];
  
    for (let i = 0; i < files.length; i++) {
      console.log(files[i]);
      allFiles.push(files[i]);
    }
  
    setFiles(allFiles);
  }
  const { t } = useTranslation();
  console.log(error?.error?.data);
  async function Onclick(params) {
   formdata.append("name", state.name);

  
 
   formdata.append("imgs", files);
   PostWorkout(formdata)
      .unwrap()
      .then((res) => {
        console.log(res)
        swal("success", "success", res.status)})

      .catch((error) => {
        console.log(error);
        if (error) {
          swal("error", "error", error?.data?.message);
        }
      });
  }
  const [state, setState] = React.useState({});
  return (
    <div style={{ height: "90vh", width: "70vw", margin: "20px" }}>
      <Form>
        <FormGroup>
          <Label style={{ color: themeColor.text }}>{t("Name")}</Label>
  
          

<Input
  className="mb-3"
  type="text"
  name="Name"
  onChange={(e) => setState({ ...state, name: e.target.value })}
></Input>
          <input type="file" multiple  onChange={handleChange} />
           {
              files[0]?
              <img src={URL.createObjectURL(files[0])} width="400" />
              :
              null
            }{
              files[1]?
              <img src={URL.createObjectURL(files[1])} width="400" />
              :
              null
           } 
           {
              files[2]?
              <img src={URL.createObjectURL(files[2])} width="400" />
              :
              null
           } 


        </FormGroup>

       
        <Button
          style={{ background: themeColor.active, float: "left" }}
          onClick={(e) => Onclick()}
        >
          New WorkOut
        </Button>
      </Form>
    </div>
  );
};

export default NewWorkout;
