// import { IconButton } from '@mui/material';
// import { AndroidPushNotificationPreview, ApplePushNotificationPreview, PushNotificationPreview } from "push-notification-preview";

import React, { useEffect, useState } from "react";
import Loading from "../../components/lodaing/Loading";

import {
  Button,
  Switch,
} from "@mui/material";
import { swal } from "../../components/swal";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { BiDetail, BiSave } from "react-icons/bi";
import {  useGetFollowupQuery, useGetFollowupsQuery, useGetGroupsQuery, useGetProductsQuery,
  usePostGruopMutation,
  useUpateProductsMutation, } from "../../app/Selice/FollowupSelice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetMealsQuery } from "../../app/Selice/MealsSelice";


import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useGetWorkoutQuery } from "../../app/Selice/WorkOutSelice";



function NewGroups() {
  const { t } = useTranslation();
  const { data, isLoading, isFetching, isSuccess } =
  useGetWorkoutQuery(1,10);
  const [PostGruop, error] = usePostGruopMutation();

  const [state, setstate] = React.useState([]);
  const [state2, setstate2] = React.useState([]);
  const [table, settable] = React.useState([]);


  //get id from url 
  let { id } = useParams();
  

 

  const themeColor = useSelector((state) => state.theme.value);
  const [loading, isloading] = useState(0);


 
function add(params) {
  const newData = (data) => [...data, { number:state.number,
    rep:state.rep,
    time:state.time,
    workout_id:state.workout_id,
    quantity:state.quantity,}];
  settable(newData);


  

 
}
async function Onclick(params) {
  const formdata = new FormData();

console.log(table)
 

 
   PostGruop({
    followup_id:id,
    day: state2.day,
    action: state2.action,
    followupworkouts:table,
   })
     .unwrap()
     .then((res) => {
       console.log(res)
       swal("success", "success", res.status)})

     .catch((error) => {
       console.log(error);
       if (error) {
         swal("error", "error", error?.data?.message);
       }
     });
 }
  return (
 
        <>
  
  {isLoading ? (
        <Loading />
      ) : (
        <div className="" style={{ width: "75vw" }}>


<Form style={{


    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "20px",
  
    margin: "20px",
    minHeight: "70vh",

    boxShadow: "0 0 20px 0 rgba(0,0,0,0.2)",
    

  
}}>
 
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Day</Form.Label>
          <Form.Control type="text" placeholder="Enter day" onChange={(e)=>{
            setstate2({...state2,day:e.target.value})
          }}/>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridPassword">
          <Form.Label>action</Form.Label>
          <Form.Control type="text" placeholder="Enter action" onChange={(e)=>{
             setstate2({...state2,action:e.target.value})}}
             />
        </Form.Group>
      </Row>

    <hr></hr>
    <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Work Out</Form.Label>
      <select 
          style={{
            width: "100%",
            height: "40px",
            border: "1px solid #ced4da",
            borderRadius: "0.25rem",
            padding: "0 0.75rem",
            color: "#495057",
            backgroundColor: themeColor.mood,
            backgroundClip: "padding-box",
          }}
      onChange={(e)=>{
        setstate({...state, workout_id:e.target.value})
      }}
      >
       
      {
        data?.data?.map((data)=>{
          return(<option key={data.id} value={data.id}>
          {data.name}
        </option>)

      })}
      </select>
        </Form.Group>
    <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Number</Form.Label>
          <Form.Control type="number" placeholder="Enter number" 
          onChange={(e)=>{
            setstate({...state,number:e.target.value})
          }}
           />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridPassword">
          <Form.Label>rep</Form.Label>
          <Form.Control type="number" placeholder="Enter rep" 
          onChange={
            (e)=>{
              setstate({...state,rep:e.target.value})
            }
          }/>
        </Form.Group>
      </Row>
      
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Time</Form.Label>
          <Form.Control type="number" placeholder="Enter time" onChange={
            (e)=>{
              setstate({...state,time:e.target.value})
            }
          } />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridPassword">
          <Form.Label>Quantity</Form.Label>
          <Form.Control type="number" placeholder="Enter Quantity" onChange={
            (e)=>{
              setstate({...state,quantity:e.target.value})
            }

          } />
        </Form.Group>
       
      </Row>
    
      <Button size="large"
   onClick={(e)=>{
    add(e)
   }

   }

      
      >New</Button> 

<table className="table" id="customers">
        <thead>
          <tr>

       

            <th>
              <h3
                style={{
            
                  color: "white",
                  float: "center",
                }}
              >
               Number(index)
              </h3>
            </th>
            <th>
              <h3
                style={{
            
                  color: "white",
                  float: "center",
                }}
              >
               reps
              </h3>{" "}
            </th>
            <th>
              <h3
                style={{
            
                  color: "white",
                  float: "center",
                }}
              >
              Time
              </h3>
            </th>
            <th>
              <h3
                style={{
            
                  color: "white",
                  float: "center",
                }}
              >
            Quantity
              </h3>
            </th>
            <th>
              <h3
                style={{
            
                  color: "white",
                  float: "center",
                }}
              >
       workout_id
              </h3>
            </th>
          </tr>
        </thead>
        <tbody>
 {table?.map((data, index) => {
            return (
              <tr key={index}>
                <td>{data.number}</td>
                <td>{data.rep}</td>
                <td>{data.time}</td>
                <td>{data.quantity}</td>
                <td>
                  {
                    data. workout_id
                  }
                </td>

              </tr>
            );
          })} 
    

        </tbody>
 
       

      </table>
      <Button
          style={{  float:"right" }}
          onClick={(e) => Onclick()}
        >
          New Meals
        </Button>
    </Form>
   
       
               
 
 
     
          
    
      
    </div>
  )}
</>)
}



export default NewGroups;