import { apiSlice } from "./apiSelice"

export const ShowStatisticsSelice = apiSlice.injectEndpoints({
    endpoints: builder => ({
       GetShowStatistics:builder.query({
            query:()=>'/statistics'
       }),
       providesTags: ['FeedBack'],
    })
})

export const {
    useGetShowStatisticsQuery,
} = ShowStatisticsSelice