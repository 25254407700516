import { apiSlice } from "./apiSelice"

export const MealsSelice = apiSlice.injectEndpoints({
    
    endpoints: builder => ({
        
       GetMeals:builder.query({
            query:(page)=>'/meals?page=1&paginate=100',
            providesTags:['Meals']
       }),
       UpateMeals:builder.mutation({
        query:({...rest})=>({
       
              url:`/meals/${rest.id}`,
              method:'PATCH',
              body:rest,
          
            
        }),
        invalidatesTags: ['Meals'],

 }),
 
 PostMeals:builder.mutation({
    
    query:(formdata)=>({
          url:`/meals`,
          header:{
            'Content-Type':`multipart/form-data`,          
          },
          method:'POST',
          body:formdata,
       
    }

    ),
    
    invalidatesTags: ['Meals'],

})
    })
})

export const {
    useGetMealsQuery,
    useUpateMealsMutation,
    usePostMealsMutation

} = MealsSelice