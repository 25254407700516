// import { IconButton } from '@mui/material';
// import { AndroidPushNotificationPreview, ApplePushNotificationPreview, PushNotificationPreview } from "push-notification-preview";

import React, { useEffect, useState } from "react";
import Loading from "../../components/lodaing/Loading";

import {
  Button,
  Switch,
} from "@mui/material";
import { swal } from "../../components/swal";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { BiDetail, BiSave } from "react-icons/bi";
import {  useGetFollowupQuery, useGetFollowupsQuery, useGetProductsQuery,
  useUpateProductsMutation, } from "../../app/Selice/FollowupSelice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetMealsQuery } from "../../app/Selice/MealsSelice";



function OneFollowup() {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  //get id from url 
  let { id } = useParams();
  
  console.log(id)
  const { data, isLoading, isFetching, isSuccess } =
  useGetFollowupQuery(id);

  const themeColor = useSelector((state) => state.theme.value);
  const [loading, isloading] = useState(0);

console.log(data)
//  var columns =[]
//  data?.meta?.columns?.map((item)=>{
//   columns.push({
//     field: item, headerName: t(item),   flex:1 
//   })
//  })

  return (
    <>
      {!isSuccess   ? (
        <Loading />
      ) : (
    
      
        <div  style={{ width: "75vw" }}>
        <div className="container0">
     <div className="main-body">
     
   
           <nav aria-label="breadcrumb" className="main-breadcrumb">
             <ol className="breadcrumb">
               <li className="breadcrumb-item"><a href="index.html">Home</a></li>
               <li className="breadcrumb-item"><a href="javascript:void(0)">Foolowup</a></li>
               <li className="breadcrumb-item active" aria-current="page">Foolowup Profile</li>
             </ol>
           </nav>
        
     
           <div className="row gutters-sm">
             <div className="col-md-4 mb-3">
               <div className="card">
                 <div className="card-body">
                   <div className="d-flex flex-column align-items-center text-center">
                     {/* // data[0]?.images.name?data[0]?.images[0].name:  */}
                     <img src={data[0]?.images[0]?.name ? data[0]?.images[0]?.name :"https://bootdey.com/img/Content/avatar/avatar7.png"} alt="Admin" className="rounded-circle" width="150"/>
                     <div className="mt-3">
                       <h4>Username:{
                       data[0]?.users[0].username?data[0]?.users[0].username:"no name"
                         }</h4>
                      <h4>height
 :{
                       data[0]?.users[0].height?data[0]?.users[0].height:"no height"
                         }</h4>
                          <h4>weight
 :{
                       data[0]?.users[0].weight
                       ?data[0]?.users[0].weight
                       :"no weight"
                         }</h4>
                       {
                         //two button
                       }
                       <div className="btn-group" role="group" aria-label="Basic example">
                       <Link to={`/Followups/${id}/groups`}>
                       <button type="button" className="btn btn-primary">تمارين</button>
                       </Link>
 {
   //space between button
 
 }
                       <div style={{width:"10px"}}></div>
                       <Link to={`/Followups/${id}/meals`}>
                       <button type="button" className="btn btn-primary">وجبات</button>
                       </Link>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             
             </div>
             <div className="col-md-8">
               <div className="card mb-3">
                 <div className="card-body">
                 <h6 className="d-flex align-items-center mb-3"><i className="material-icons text-info mr-2"/>Trineer</h6>
                   <div className="row">
                     <div className="col-sm-3">
                       <h6 className="mb-0">Full Name</h6>
                     </div>
                     <div className="col-sm-9 text-secondary">
                    {
                       data[0]?.users[0].name?data[0]?.users[0].name:"no name"
                    }
                     </div>
                   </div>
                   <hr/>
                   <div className="row">
                     <div className="col-sm-3">
                       <h6 className="mb-0">aim</h6>
                     </div>
                     <div className="col-sm-9 text-secondary">
                    {
                       data[0]?.users[0].aim?data[0]?.users[0].aim:"no aim"
                    }
                     </div>
                   </div>
                   <hr/>
                   <div className="row">
                     <div className="col-sm-3">
                       <h6 className="mb-0">Phone</h6>
                     </div>
                     <div className="col-sm-9 text-secondary">
                     {
                       data[0]?.users[0].phone?data[0]?.users[0].phone:"no phone"
                     }
                     </div>
                   </div>
                   <hr/>
                   <div className="row">
                     <div className="col-sm-3">
                       <h6 className="mb-0">objective
 </h6>
                     </div>
                     <div className="col-sm-9 text-secondary">
                    {
                       data[0]?.users[0].objective?data[0]?.users[0].objective:"no objective"
                    }
                     </div>
                   </div>
                   <hr/>
                   <div className="row">
                     <div className="col-sm-3">
                       <h6 className="mb-0">focuson
 </h6>
                     </div>
                     <div className="col-sm-9 text-secondary">
                       {
                       data[0]?.users[0].focuson?data[0]?.users[0].focuson:"no focuson"
                       }
                     </div>
                   </div>
                   <hr/>
                   <div className="row">
                    {
                     //edit
                    }
                   </div>
                 </div>
               </div>
 
               <div className="card mb-3">
                 <div className="card-body">
                 <h6 className="d-flex align-items-center mb-3"><i className="material-icons text-info mr-2"/>Trineer</h6>
                   <div className="row">
                     <div className="col-sm-3">
                       <h6 className="mb-0">Weight</h6>
                     </div>
                     <div className="col-sm-9 text-secondary">
                    {
                       data[0]?.users[0].weight?data[0]?.users[0].weight:"no weight"
                    }
                     </div>
                   </div>
                   <hr/>
                   <div className="row">
                     <div className="col-sm-3">
                       <h6 className="mb-0">Fat</h6>
                     </div>
                     <div className="col-sm-9 text-secondary">
                    {
                       data[0]?.fat?data[0]?.fat:"no fat"
                    }
                     </div>
                   </div>
                   <hr/>
                
                  
                 </div>
               </div>
 
 
 
             </div>
           </div>
 
         </div>
     </div>
      </div>
   


      )}
     
    </>
  );


}

export default OneFollowup;