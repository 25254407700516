import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import { useTranslation } from "react-i18next";
import { swal } from "../../components/swal";
import { usePostMealsMutation } from "../../app/Selice/MealsSelice";

const NewMeals = () => {
  const themeColor = useSelector((state) => state.theme.value);
  const [PostMeals, error] = usePostMealsMutation();
  var formdata = new FormData();
  const [file, setFile] = useState();
  function handleChange(e) {
   console.log(e.target.files[0])
   
      setFile(e.target.files[0]);
  }
  const { t } = useTranslation();
  console.log(error?.error?.data);
  async function Onclick(params) {
   formdata.append("name", state.name);
   formdata.append("weight",state.weight);
  formdata.append("fat",state.fat);
  formdata.append("calories",state.calories); 
   formdata.append("carb",state.carb); 
    formdata.append("protein",state.protein);
  
 
   formdata.append("img", file);
    PostMeals(formdata)
      .unwrap()
      .then((res) => {
        console.log(res)
        swal("success", "success", res.status)})

      .catch((error) => {
        console.log(error);
        if (error) {
          swal("error", "error", error?.data?.message);
        }
      });
  }
  const [state, setState] = React.useState({});
  return (
    <div style={{ height: "90vh", width: "70vw", margin: "20px" }}>
      <Form>
        <FormGroup>
          <Label style={{ color: themeColor.text }}>{t("Name")}</Label>

          <Input
            className="mb-3"
            type="text"
            name="Name"
            onChange={(e) => setState({ ...state, name: e.target.value })}
          ></Input>
               <Label style={{ color: themeColor.text }}>{t("weight")}</Label>

<Input
  className="mb-3"
  type="text"
  name="Name"
  onChange={(e) => setState({ ...state, weight: e.target.value })}
></Input>
<Label style={{ color: themeColor.text }}>{t("fat")}</Label>

<Input
  className="mb-3"
  type="text"
  name="Name"
  onChange={(e) => setState({ ...state, fat: e.target.value })}
></Input>
<Label style={{ color: themeColor.text }}>{t("calories")}</Label>

<Input
  className="mb-3"
  type="text"
  name="Name"
  onChange={(e) => setState({ ...state, calories: e.target.value })}
></Input>
<Label style={{ color: themeColor.text }}>{t("carb")}</Label>

<Input
  className="mb-3"
  type="text"
  name="Name"
  onChange={(e) => setState({ ...state, carb: e.target.value })}
></Input>
<Label style={{ color: themeColor.text }}>{t("protein")}</Label>

<Input
  className="mb-3"
  type="text"
  name="Name"
  onChange={(e) => setState({ ...state, protein: e.target.value })}
></Input>
          <h6 className="red">{error?.error?.data?.errors.name}</h6>
          <input type="file" onChange={handleChange} />
           {
              file?
              <img src={URL.createObjectURL(file)} width="400" />
              :
              null
           } 
           


        </FormGroup>

       
        <Button
          style={{ background: themeColor.active, float: "left" }}
          onClick={(e) => Onclick()}
        >
          New Meals
        </Button>
      </Form>
    </div>
  );
};

export default NewMeals;
