import { apiSlice } from "./apiSelice"

export const SystemInfoSelice = apiSlice.injectEndpoints({
    endpoints: builder => ({
       GetSystemInfo:builder.query({
            query:()=>'/SystemInfo'
       }),
       UpateSystemInfo:builder.mutation({
              query:({...rest})=>({
             
                    url:`/SystemInfo/1`,
                    method:'PATCH',
                    body:rest
              })
       })
    })
})

export const {
    useGetSystemInfoQuery,
    useUpateSystemInfoMutation
} = SystemInfoSelice