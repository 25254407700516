import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { swal } from "../../components/swal";

import { logOut, setCredentials } from "../../features/user";

const token = localStorage.getItem('token')
const baseQuery = fetchBaseQuery({
  baseUrl: 'http://3.223.187.125:8022/api/v1/admin',
  credentials: 'include',
  headers:{
  //  'Content-Type': 'application/json',
    'Accept': 'application/json',
    'authorization':`Bearer ${token}`
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result?.error) {
    //logout user if refresh token fails or user is not authenticated
    // if (result.error.status === 401 || result.error.status === 400) {
    //   api.dispatch(logOut())
    //   //delete token from local storage
    //   localStorage.clear()
    //   //redirect user to login page
    //   window.location.href = '/login'

    // }
   return    swal("error", "error", result.error?.data?.message);
 
  }

  return result
}

export const apiSlice = createApi({
    baseQuery : baseQueryWithReauth,
    tagTypes:['Location','OrderType','FeedBack','Meals',
  'SubMeals',
  'UserGifts',
  'User',
  'Order',
  'Product',
  'ProductType',
  ],
    endpoints: (builder) => ({})});
