// import { IconButton } from '@mui/material';
// import { AndroidPushNotificationPreview, ApplePushNotificationPreview, PushNotificationPreview } from "push-notification-preview";

import React, { useEffect, useState } from "react";
import Loading from "../../components/lodaing/Loading";
import {
  useGetTraineeQuery,
  useUpateTraineeMutation,
} from "../../app/Selice/TraineeSelice";
import {
  Button,
  CardActions,
  CardContent,
  CardMedia,
  Switch,
  Typography,
} from "@mui/material";
import { swal } from "../../components/swal";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
import { paginate } from "../../components/paginate/paginate";

function Trainee() {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = React.useState(1);
  const { data, error, isLoading, isFetching, isSuccess } =
    useGetTraineeQuery(pageSize);
  const themeColor = useSelector((state) => state.theme.value);
  const [loading, isloading] = useState(false);
  const [UpdateTrainee] = useUpateTraineeMutation();

  let arr = [];
  for (let i = 0; i <= data?.meta?.last_page-1; i++) {
    arr.push(i);
  }
console.log(data)
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flexsx" style={{ width: "75vw" }}>
          {data.data.map((data, index) => (
            <div>
              <Card
                key={index}
                sx={{ maxWidth: 345 }}
                style={{ margin: "10px", width: 250 }}
              >
                <CardMedia
                  sx={{ height: 250 }}
                  image={data?.image
                  }
                  title="green iguana"
                />
              
                <CardContent>
                  
                  <Typography gutterBottom variant="h5" component="div">
                    {data.name}
                   
                    <Switch
                      checked={data.status == "true" ? true : false}
                      onChange={(e) => {
                        UpdateTrainee({
                          id: data.id,
                          status: data.status == "true" ? false : true,
                        })
                          .unwrap()
                          .then((res) => {
                            swal("success", "success", res.status);
                          })
                          .catch((error) => {
                            swal("error", "error", error?.data?.message);
                          });
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Typography>
                  { data.dec
                    }
                </CardContent>
                <CardActions>
                  <Button size="small">Edit</Button>

                  <Button size="small">Delete</Button>
                </CardActions>
              </Card>
            </div>
          ))}
        </div>
      )}
      <div>
        {
          data?.data?.length > 0 &&
          paginate(
            arr,
            isFetching,
            pageSize,
            setPageSize,

          )
        }
      </div>
    </>
  );


}

export default Trainee;
