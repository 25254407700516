// import { IconButton } from '@mui/material';
// import { AndroidPushNotificationPreview, ApplePushNotificationPreview, PushNotificationPreview } from "push-notification-preview";

import React, { useEffect, useState } from "react";
import Loading from "../../components/lodaing/Loading";

import {
  Button,
  Switch,
} from "@mui/material";
import { swal } from "../../components/swal";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { BiDetail, BiSave } from "react-icons/bi";

import { Link } from "react-router-dom";
import { useGetMealsQuery } from "../../app/Selice/MealsSelice";



function Meals() {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const { data, isLoading, isFetching, isSuccess } =
  useGetMealsQuery(page,pageSize);
 
  const themeColor = useSelector((state) => state.theme.value);
  const [loading, isloading] = useState(0);
 console.log(data)
 var columns =[]
 data?.meta?.columns?.map((item)=>{
  columns.push({
    field: item, headerName: t(item),   flex:1 
  })
 })
//   const columns = [
//     { field: 'id', headerName: 'ID',   flex:1 },
  
//     {
//       field: 'name',
//       headerName: t('Name'),
//       flex:1,
//       editable: true,
//     },
//     {
//       field: 'price',
//       headerName: t('price'),
//       flex:1,
//       editable: true,
//     },
//     {
//       field: 'quantity',
//       headerName: t('quantity'),
//       flex:1,
//       editable: true,
//     },
//      {
//     field: "status",
//     headerName: t('status'),
//     flex: 1,
//     renderCell: (params) => {
      
//       const onClick = (e) => {
//         if (params.row.status == false) {
//           console.log(params.row)
//           UpateProductsMutation({...params.row,status:'true'}).then(

//        swal(
//           'success', 'success', 'success'
//        )
//           )
//         };
//         if (params.row.status == true) {
//           console.log(params.row)
//           UpateProductsMutation({...params.row,status:'false'}).then(
//        swal(
//           'success', 'success', 'success'
//        )
//           )
//         };
  
//     }
  
//       if (params.row.status == true) {
//         return <Switch defaultChecked onChange={() => onClick('Disactive')} />;
//       } else {
//         return <Switch onChange={() => onClick('Active')} />;
//       }
//     },
//   },
//   {
//     field: "VIEW",
//     headerName: t("VIEW"),
//     sortable: 0,
//     renderCell: (params) => {   
//       return <Link style={{color:'#1876D1'}} to={params.row.id} >عرض</Link>;
  
//   },
// },

//   {
//     field: "actionEdit",
//     headerName: t("Save"),
//     sortable: 0,
//     renderCell: (params) => {
//       const onClick = (e) => {
//         isloading(true)
//         UpateProductsMutation(params.row).then(  
//             swal(
//               'success', 'success', 'success'
//             )
//         )
//         isloading(0)
//       };

//       return !loading?<Button onClick={onClick}><BiDetail /></Button>:'saving';
//     },
//   },
  
//   ];
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flexsx" style={{ width: "75vw" }}>
        <DataGrid
      style={{height:"80vh",width:"75vw",margin:'20px',color:themeColor.text}}
        rows={data?data.data:[]}
        columns={columns}
       pageSize={pageSize}
          onPageSizeChange={
            (newPageSize) => setPageSize(newPageSize)
          }
  onPageChange={(newPage) => setPage(page+1)}
          rowsPerPageOptions={[10,25,50,100]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
        </div>
      )}
     
    </>
  );


}

export default Meals;
