// import { IconButton } from '@mui/material';
// import { AndroidPushNotificationPreview, ApplePushNotificationPreview, PushNotificationPreview } from "push-notification-preview";

import React, { useEffect, useState } from "react";
import Loading from "../../components/lodaing/Loading";

import {
  Button,
  Switch,
} from "@mui/material";
import { swal } from "../../components/swal";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { BiDetail, BiSave } from "react-icons/bi";
import {  useGetFollowupQuery, useGetFollowupsQuery, useGetGroupsQuery, useGetProductsQuery,
  useGroupsworkoutQuery,
  useUpateProductsMutation, } from "../../app/Selice/FollowupSelice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetMealsQuery } from "../../app/Selice/MealsSelice";



function Groupsworkout() {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  //get id from url 
  let { id } = useParams();
  
  console.log(id)
  const { data, isLoading, isFetching, isSuccess } =
  useGroupsworkoutQuery(id);

  const themeColor = useSelector((state) => state.theme.value);
  const [loading, isloading] = useState(0);
if(data){
  console.log(data)
}

 var columns =[
  { field: 'id', headerName: 'ID',   flex:1 },
  {
    field:'workoutname',
    headerName:'اسم التمرين',
    flex:1 ,
    renderCell: (params) => {   
      return params?.row.workouts      [0].name
  
  },},
  {
    field:'number',
    headerName:t('number'),
    flex:1 


  },
  //quantity
{
  field:'quantity',
  headerName:t('quantity'),
  flex:1 
},
  //
  {
    field:'rep',
    headerName:t('rep'),
    flex:1 
  },
  {
    field:'time',
    headerName:t('time'),
    flex:1 
  }
 ]


 

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
       
        <div className="" style={{ width: "75vw" }}>


               
 
       
               
 
 
     
          
        <DataGrid
      style={{height:"80vh",width:"75vw",margin:'20px',color:themeColor.text}}
        rows={data?data.data:[]}
        columns={columns}
       pageSize={pageSize}
          onPageSizeChange={
            (newPageSize) => setPageSize(newPageSize)
          }
  onPageChange={(newPage) => setPage(page+1)}
          rowsPerPageOptions={[10,25,50,100]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
        </div>
   


      )}
     
    </>
  );


}

export default Groupsworkout;