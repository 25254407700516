// import { IconButton } from '@mui/material';
// import { AndroidPushNotificationPreview, ApplePushNotificationPreview, PushNotificationPreview } from "push-notification-preview";

import React, { useEffect, useState } from "react";
import Loading from "../../components/lodaing/Loading";

import { Button, Switch } from "@mui/material";
import { swal } from "../../components/swal";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { BiDetail, BiSave } from "react-icons/bi";
import {

  useGetMealsDayQuery,

} from "../../app/Selice/FollowupSelice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetMealsQuery } from "../../app/Selice/MealsSelice";

function Daymaels() {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  //get id from url
  let { id } = useParams();
  //get  url between  Followups/  and /meals?
  const navigate = useNavigate();
  const [url, setUrl] = useState( window.location.href.substring(
    window.location.href.lastIndexOf("/Followups/") + 11,
    window.location.href.lastIndexOf("/meals")
  ));
  //get url between  Followups/  and /meals?



  const { data, isLoading, isFetching, isSuccess } = useGetMealsDayQuery({
    id,url}  );
 
 console.log(data)



  return (
    <>
   
      {!isSuccess ? (
        <Loading />
      ) : (
        <table className="table" id="customers">
      <thead>
        <tr>

     

          <th>
            <h3
              style={{
          
                color: "white",
                float: "center",
              }}
            >
           رقم الوجبة
            </h3>
          </th>
          <th>
            <h3
              style={{
          
                color: "white",
                float: "center",
              }}
            >
         اسم الوجبة
            </h3>
          </th>
          <th>
            <h3
              style={{
          
                color: "white",
                float: "center",
              }}
            >
        صورة الوجبة
            </h3>
          </th>
          <th>
            <h3
              style={{
          
                color: "white",
                float: "center",
              }}
            >
      عدد قرمات الوجبة
            </h3>
          </th>
         
      
         
     
        </tr>
      </thead>
      <tbody>
{
data?.data?.map((item,index)=>{
  return (
    <tr>
   
    <td>
      <h5
        style={{
    

          float: "center",
        }}
      >
    {
     item.number
    }
      </h5>
    </td>
    <td>
      <h5
        style={{
    

          float: "center",
        }}
      >
    {
      item?.meals[0].name
    }
      </h5>
    </td>
    <td>
     
    {
     <img 
      src={item?.meals[0].image}
      style={{width:"100px",height:"100px"}}
      />

    }
      
    </td>
    <td  style={{
      // backgroundColor: "white",
      justifyContent: "center",
    

    float: "center",
  }}>
    {item.weight
}

 
    
    </td>
    </tr>
  )
})


}
  

      </tbody>

     

    </table>
      )}


            
    </>
  );
}

export default Daymaels;
