
import React, { useEffect, useState } from "react";
import Loading from "../../components/lodaing/Loading";

import {
  Button,
  Switch,
} from "@mui/material";
import { swal } from "../../components/swal";

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetMealsQuery } from "../../app/Selice/MealsSelice";
import { usePostFollowupsMealsMutation } from "../../app/Selice/FollowupSelice";



function NewFolMeals() {
  const { t } = useTranslation();
  const [state, setstate] = React.useState([]);
  const [state2, setstate2] = React.useState([]);
  const [table, settable] = React.useState([]);
  //get id from url 
  let { id } = useParams();
  //usePostFollowupsMealsMutation
  const [Postmealfollwup]=usePostFollowupsMealsMutation()  
  console.log(id)
  const { data, isLoading, isFetching, isSuccess } =
 useGetMealsQuery();

  const themeColor = useSelector((state) => state.theme.value);
  const [loading, isloading] = useState(0);


  function add(params) {
    const newData = (data) => [...data, {
      'meal_id':state.meal_id,
      'followup_id':id,
      weight: state.weight,
      number: state.number,
      day: state.day,

      }];
    settable(newData);
  
  
    
  
   
  }
  async function Onclick(params) {
   
  

   
 
  console.log(table)

   
  Postmealfollwup(table)
       .unwrap()
       .then((res) => {
         console.log(res)
         swal("success", "success", res.status)})
  
       .catch((error) => {
         console.log(error);
         if (error) {
           swal("error", "error", error?.data?.message);
         }
       });
  }

  return (
 
    <>

{isLoading ? (
    <Loading />
  ) : (
    <div className="" style={{ width: "75vw" }}>


<Form style={{


border: "1px solid #ccc",
borderRadius: "5px",
padding: "20px",

margin: "20px",
minHeight: "70vh",

boxShadow: "0 0 20px 0 rgba(0,0,0,0.2)",



}}>

 

<hr></hr>
<Form.Group as={Col} controlId="formGridEmail">
      <Form.Label>Meals</Form.Label>
  <select 
      style={{
        width: "100%",
        height: "40px",
        border: "1px solid #ced4da",
        borderRadius: "0.25rem",
        padding: "0 0.75rem",
        color: "#495057",
        backgroundColor: themeColor.mood,
        backgroundClip: "padding-box",
      }}
  onChange={(e)=>{
    setstate({...state, meal_id:e.target.value})
  }}
  >
      <option key={''} value={''}>

</option> 
  {

    data?.data?.map((data)=>{
      return(<option key={data.id} value={data.id}>
      {data.name}
    </option>)

  })}
  </select>
    </Form.Group>
<Row className="mb-3">
    <Form.Group as={Col} controlId="formGridEmail">
      <Form.Label>Number</Form.Label>
      <Form.Control type="number" placeholder="Enter number" 
      onChange={(e)=>{
        setstate({...state,number:e.target.value})
      }}
       />
    </Form.Group>

    <Form.Group as={Col} controlId="formGridPassword">
      <Form.Label>day</Form.Label>
      <Form.Control type="number" placeholder="Enter day" 
      onChange={
        (e)=>{
          setstate({...state,day:e.target.value})
        }
      }/>
    </Form.Group>
  </Row>
  
  <Row className="mb-3">
   
    <Form.Group as={Col} controlId="formGridPassword">
      <Form.Label>weight</Form.Label>
      <Form.Control type="number" placeholder="Enter weight" onChange={
        (e)=>{
          setstate({...state,weight:e.target.value})
        }

      } />
    </Form.Group>
   
  </Row>

  <Button size="large"
onClick={(e)=>{
add(e)
}

}

  
  >New</Button> 

<table className="table" id="customers">
    <thead>
      <tr>

   

        <th>
          <h3
            style={{
        
              color: "white",
              float: "center",
            }}
          >
           Number(index)
          </h3>
        </th>
        <th>
          <h3
            style={{
        
              color: "white",
              float: "center",
            }}
          >
     day
          </h3>{" "}
        </th>
        <th>
          <h3
            style={{
        
              color: "white",
              float: "center",
            }}
          >
          weight
          </h3>
        </th>
        <th>
          <h3
            style={{
        
              color: "white",
              float: "center",
            }}
          >
    number
          </h3>
        </th>
        <th>
          <h3
            style={{
        
              color: "white",
              float: "center",
            }}
          >
meal_id
          </h3>
        </th>
      </tr>
    </thead>
    <tbody>
{table?.map((data, index) => {
        return (
          <tr key={index}>
             <td>{index+1}</td>
            <td>{data.day}</td>
            <td>{data.weight}</td>
            <td>{data.number}</td>
           
            <td>
              {
                data.meal_id
              }
            </td>

          </tr>
        );
      })} 


    </tbody>

   

  </table>
  <Button
      style={{  float:"right" }}
      onClick={(e) => Onclick()}
    >
      New Meals
    </Button>
</Form>

   
           


 
      

  
</div>
)}
</>)
}

export default NewFolMeals;