// import { IconButton } from '@mui/material';
// import { AndroidPushNotificationPreview, ApplePushNotificationPreview, PushNotificationPreview } from "push-notification-preview";

import React from 'react'

import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import { Switch } from '@mui/material';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { useGetCustomerQuery, useUpateUserMutation } from '../../app/Selice/UserSelice';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Customers() {
  const [page, setPage] = React.useState(1);
  const [UpdateUser] = useUpateUserMutation()
  const [pageSize, setPageSize] = React.useState(10);
  const themeColor = useSelector((state) => state.theme.value)
  const {t} = useTranslation();
  const {
    data, error, isLoading, isFetching, isSuccess
   }=useGetCustomerQuery( {
    page:page,
    pageSize:pageSize
   })
   const columns = [
    { field: 'id', headerName: 'ID',   flex:1,hide:true },
    {
      headerName: t('Email'),
      field: 'email',
      flex:1,
  
    },
  
    {
      headerName:    t('name'),
      field:   'name',
      flex:2,
  
    },
    {
      headerName:    t('bodytype'),  
      field: 'bodytype',
      flex:2,
    },
    {
      headerName:    t('Phone'),
      field: 'phone',
      flex:1,
  
    },
    {
      headerName: t('Role'),
      field:'role',
      flex:1,
  
    },
    {
      headerName: t('created_at'),
       field: 'created_at',
  
      flex:1,
      // renderCell: (params) => {
      //   //foramt
      //   return params.rows.created_at
      // }
  
    },
    {
      headerName:  t('IsDisabled'),
     field:    'isDisbale',
      renderCell: (params) => {
          
      
  
  
        return <Switch defaultChecked style={{color:themeColor.active}} />;
      
  
      },
      flex:1,
  
    },
    {
      field: "VIEW",
      headerName: t("VIEW"),
      sortable: 0,
      renderCell: (params) => {   
        return <Link style={{color:'#1876D1'}} to={'./'+params.row.id} >عرض</Link>;
    
    },},
  ];
  return (
    <>

<div style={{height:"80vh",width:"75vw",margin:'20px'}}> 
      <DataGrid
    style={{height:"80vh",width:"75vw",margin:'20px', color:  themeColor.text}}
        rows={data?data.data:[]}
        columns={columns}
       pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10,25,50,100]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
      </div>

   
    </>

  
 
  )
}

export default Customers;