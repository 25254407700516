import React from 'react';

import Dashboard from './Views/DashBoard/Dashboard';
import Index from './components/navbar/Index';
import { Route, Routes } from 'react-router-dom';
import  AppBar  from './components/AppBar/AppBar';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Users from './Views/Users/Users';
import Customers from './Views/Customers/Customers';
import NewUser from './Views/Users/NewUser';
import ListNotification from './Views/Notification/ListNotification';
import Notification from './Views/Notification/Notification';
import FeedBack from './Views/FeedBack/FeedBack';
import Settings from './Views/Settings/Settings';
import Orders from './Views/Order/Orders';
import Login from './Views/Login/Login';
import { useTranslation } from 'react-i18next';
import System from './Views/System/System';

import RequireAuth from './context/RequireAuth';
import Meals from './Views/Meals/Meals';
import NewMeals from './Views/Meals/NewMeals';

import Trainee from './Views/Trainee/Trainee';
import NewTrainee from './Views/Trainee/NewTrainee';
import Order from './Views/Order/Order';
import Workouts from './Views/WorkOut/WorkOut';
import { WorkoutSelice } from './app/Selice/WorkOutSelice';
import NewWorkout from './Views/WorkOut/NewWorkOut';
import Followups from './Views/Followup/Followups';
import OneFollowup from './Views/Followup/oneFollowup';
import Group from '@mui/icons-material/Group';
import Groups from './Views/Followup/Groups';
import Groupsworkout from './Views/Followup/Groupsworkout';
import NewGroups from './Views/Followup/NewGroups';
import GroupMeals from './Views/Followup/Meals';
import Daymaels from './Views/Followup/Daymaels';
import OneCustomer from './Views/Customers/OneCustomer';
import NewFolMeals from './Views/Followup/NewMeals';



function App() {
  const themeColor = useSelector((state) => state.theme.value)

  let location = useLocation()

  const {t,i18n} = useTranslation();
 let language =i18n.language
 
  return (
    <div className="App" style={{backgroundColor:themeColor.mood}} dir={`${language == 'ar'?'rtl':''}`}>
   <div className="flex h-100" >



{location.pathname === '/login'  ? null : <Index/>}
<div >

{location.pathname === '/login'  ? null : <AppBar/>}
<Routes>
<Route path="/login"  element={ <Login/>}></Route>

<Route  element={<RequireAuth />}>
<Route path="/"  element={ <Dashboard/>}></Route>

<Route path="/Customers"  element={ <Customers/>}></Route>
<Route path="/Customers/:id"  element={ <OneCustomer/>}></Route>
<Route path="/DashBoard"  element={ <Dashboard/>}></Route>
<Route path="/Users" element={ <Users/>}></Route>
<Route path="/Users/NewUser"  element={ <NewUser/>} ></Route>
<Route path="/Notifications"  element={ < ListNotification/>} ></Route>
<Route path="/Notifications/NewNotification"  element={ <Notification/>} ></Route>
<Route path="/Meals/newmeal"  element={ <NewMeals />} ></Route>
<Route path="/Meals"  element={ <Meals/>} ></Route>
<Route path="/Workout/NewWorkout"  element={ <NewWorkout />} ></Route>
<Route path="/Workout"  element={ <Workouts/>} ></Route>
<Route path="/Followups"  element={ <Followups/>} ></Route>
<Route path="/Followups/:id"  element={ <OneFollowup/>} ></Route>
<Route path="/Followups/:id/groups"  element={ <Groups/>} ></Route>
<Route path="/Followups/:id/meals"  element={ <GroupMeals/>} ></Route>
<Route path="/Followups/:id/meals/:id"  element={ <Daymaels/>} ></Route>
<Route path="/Followups/:id/groups/:id"  element={ <Groupsworkout/>} ></Route>
<Route path="/Followups/:id/groups/newday"  element={ <NewGroups/>} ></Route>

<Route path="/Followup/GroupMeals/:id/NewMeals"  element={ <NewFolMeals/>} ></Route>
{/* <Route path="/Workout/NewWorkout"  element={ <NewWorkout />} ></Route>
{/* <Route path="/ProductStatus/NewProductStatus"  element={ <NewProductStatus />} ></Route>
<Route path="/ProductStatus"  element={ <ProductStatus/>} ></Route> */}
{/* <Route path="/OrderStatus/NewOrderStatus"  element={ <NewOrderStatus />} ></Route>
<Route path="/OrderStatus"  element={ <OrderStatus/>} ></Route> */}
{/* <Route path="/Citys/NewCity"  element={ <NewCitys />} ></Route>
<Route path="/Citys"  element={ <Citys/>} ></Route>
<Route path="/Colors/NewColor"  element={ <NewColor />} ></Route>
<Route path="/Colors"  element={ <Colors/>} ></Route> */}
<Route path="/Trainee/NewTrainee"  element={ <NewTrainee />} ></Route>
<Route path="/Trainee"  element={ <Trainee/>} ></Route>
{/* 
<Route path="/Gifts/NewGift"  element={ <NewGifts />} ></Route>
<Route path="/Gifts"  element={ <Gifts/>} ></Route>
<Route path="/Gifts/NewGift"  element={ <NewGifts />} ></Route>
<Route path="/Payments"  element={ <Payments/>} ></Route>
<Route path="/Payments/NewPayment"  element={ <NewPayments />} ></Route> */}



<Route path="/FeedBack"  element={ <FeedBack/>} ></Route>
<Route path="/Settings"  element={ <Settings/>} ></Route>
<Route path="/Orders"  element={ <Orders/>} ></Route>
<Route path="/Orders/:id"  element={ <Order/>} ></Route>
<Route path="/System"  element={ <System/>} ></Route>
</Route>
</Routes>

</div>

</div>
    </div>
  );
}

export default App;
