// import { IconButton } from '@mui/material';
// import { AndroidPushNotificationPreview, ApplePushNotificationPreview, PushNotificationPreview } from "push-notification-preview";

import React from 'react'

import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import { Switch } from '@mui/material';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { useGetCustomerQuery, useGetOneCustomerQuery, useUpateUserMutation } from '../../app/Selice/UserSelice';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { swal } from '../../components/swal';

function OneCustomer() {

  const [UpdateUser] = useUpateUserMutation()
  const [pageSize, setPageSize] = React.useState(10);
  const themeColor = useSelector((state) => state.theme.value)
  const {t} = useTranslation();
  const {id} = useParams()
  const {
    data, error, isLoading, isFetching, isSuccess
   }= useGetOneCustomerQuery( {
   id:id
   })
   const [state, setstate] = React.useState(data);
    React.useEffect(() => {
      setstate(data)
    }, [data])
  return (
    <>

<div style={{width:"75vw",margin:'20px'}}> 
<div>
      <h2>data Profile</h2>
      <label>ID: </label>
      <input type="text" value={state?.id} readOnly />

      <label>Username: </label>
      <input type="text" value={state?.username} readOnly />

      <label>Phone: </label>
      <input type="text" value={state?.phone} readOnly />

      <label>Role: </label>
      <input type="text" value={state?.role} readOnly />

      <label>Phone is Active: </label>
      <input type="text" value={state?.phoneIsActive} readOnly />

      <label>Height: </label>
      <input type="text" value={state?.height} readOnly />

      <label>Weight: </label>
      <input type="text" value={state?.weight} readOnly />

      <label>Name: </label>
      <input type="text" value={state?.name} readOnly />

      <label>Aim: </label>
      <input type="text" value={state?.aim} readOnly />

      <label>Body Type: </label>
      <input type="text" value={state?.bodytype} readOnly />

      <label>Objective: </label>
      <input type="text" value={state?.objective} readOnly />

      <label>Focus On: </label>
      <input type="text" value={state?.focuson} readOnly />

      <label>Goal: </label>
      <input type="text" value={state?.goal} readOnly />

      <label>Is Male: </label>
      <input type="text" value={state?.ismale ? 'Yes' : 'No'} readOnly />

      <label>Birthday: </label>
      <input type="text" value={state?.birthday} readOnly />

      <label>Status: </label>
      <select type="text" value={state?.status} onChange={
        (e)=>{
          setstate({...state,status:e.target.value}) 
        }
      } >
        <option value="true">Active</option>
        <option value="false">Inactive</option>
      </select>

      <label>Created At: </label>
      <input type="text" value={state?.created_at} readOnly />

      <label>Updated At: </label>
      <input type="text" value={state?.updated_at} readOnly />

      <label>Follow-up Date: </label>
      <input type="text" value={state?.followup_date}  onChange={
        (e)=>{
          setstate({...state,followup_date:e.target.value}) 
        }
      } />
  
      <Button onClick={()=>{
        UpdateUser(state).then(
          (res)=>{
            swal(
              'success', 'success', 'success')
          }

        )
      }
      }>Update</Button>
      
    </div>
      </div>

   
    </>

  
 
  )
}

export default OneCustomer;