import { apiSlice } from "./apiSelice"
export const token = localStorage.getItem('token')
export const OrderSelice = apiSlice.injectEndpoints({
    
    endpoints: builder => ({
        
       GetOrder:builder.query({
            query:({page,pageSize})=>'/orders?page='+page+'&paginate='+pageSize,
            providesTags:['Order']
       }),
       oneOrder:builder.query({
        query:(id)=>'/orders/'+id,
        invalidatesTags: ['Order'],
    }),
       UpateOrder:builder.mutation({
        query:({...rest})=>({
       
              url:`/orders/${rest.id}`,
              method:'PATCH',
              body:rest,
          
            
        }),
        invalidatesTags: ['Order'],

 }),
 PostOrder:builder.mutation({
    query:(rest)=>({
        headers:{
            'authorization':`Bearer `+token,
            'Accept': 'application/json"]' ,
            'Content-Type': 'application/json',
          
         },
          url:`/orders`,
          method:'POST',
          body:{
            "name":rest.name,
            "price":rest.price,
          },
       
    }

    ),
    
    invalidatesTags: ['Order'],

})
    })
})

export const {
    useGetOrderQuery,
    useUpateOrderMutation,
    usePostOrderMutation,
    useOneOrderQuery

} = OrderSelice