import { apiSlice } from "./apiSelice";

export const FollowupSelice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    GetFollowups: builder.query({
      query: (a ) =>
        "/followups?page=" + a.page + "&paginate=" + a.pageSize,
      providesTags: ["Followups"],
    }),
    GetDay: builder.query({
      query: (id) =>
        "/followupmealsgruop/"+id,
      providesTags: ["Followups"],
    }),
    GetMealsDay: builder.query({
      query: (followup_id) =>{
console.log(followup_id)
     return  "/followupmeals/?filters=followup_id:eq:"+followup_id.url+",day:eq:"+followup_id.id
      }
   
    }),
    GetFollowup: builder.query({
      query: (id) => "/followups/" + id,
      providesTags: ["Followup"],
    }),
    GetGroups: builder.query({
      query: (id) => "/group?filters=followup_id:eq:" + id,
      providesTags: ["group"],
    }),

    Groupsworkout: builder.query({
      query: (id) => "/followupworkout?filters=group_id:eq:" + id,
      providesTags: ["group"],
    }),
    UpateFollowups: builder.mutation({
      query: ({ ...rest }) => ({
        url: `/followups/${rest.id}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Followups"],
    }),

    PostFollowups: builder.mutation({
      query: (formdata) => ({
        url: `/followups`,
        header: {
          "Content-Type": `multipart/form-data`,
        },
        method: "POST",
        body: formdata,
      }),

      invalidatesTags: ["Followups"],
    }),
    PostGruop: builder.mutation({
        query: (formdata) => ({
          url: `/group`,
          header: {
            "Content-Type": `multipart/form-data`,
          },
          method: "POST",
          body: formdata,
        }),
  
        invalidatesTags: ["group"],
      }),
      PostFollowupsMeals: builder.mutation({
        query: (formdata) => ({
          url: `/followupmeals`,
          header: {
            "Content-Type": `multipart/form-data`,
          },
          method: "POST",
          body: formdata,
        }),
  
        invalidatesTags: ["FollowupsMeals"],
      }),
  }),
});

export const {
  useGetFollowupsQuery,
  useUpateFollowupsMutation,
  usePostFollowupsMutation,
  useGetFollowupQuery,
  useGetGroupsQuery,
  useGetDayQuery,
  useGetMealsDayQuery,
  
  useGroupsworkoutQuery,
    usePostGruopMutation,
    usePostFollowupsMealsMutation,
} = FollowupSelice;
