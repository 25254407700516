
import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useOneOrderQuery, useUpateOrderMutation } from '../../app/Selice/OrderSelice';
import { Select } from '@mui/material';
import { swal } from '../../components/swal';
import { useEffect } from 'react';

function Order() {
  let { id } = useParams();

  const [UpdateOrder] = useUpateOrderMutation()
  const themeColor = useSelector((state) => state.theme.value)
 var {
    data, error, isLoading, isFetching, isSuccess
   }=useOneOrderQuery(id)
   console.log(data)
var a =0
const [state, setState] = React.useState(a);
   const {t} = useTranslation();

useEffect
(() => {
  setState(a)
}, [data])
  return (
    <>

<div style={{width:"75vw",margin:'20px'}}> 
 {isLoading ? (
        <h1>loading</h1>
      ) : (
        <>
        <div className="product">
        <div className="product-info">
    
            <h5 style={{color:themeColor.text}}>{t('user')} : {data?.users.name}</h5>
            <h5 style={{color:themeColor.text}}>{t('phone')} : {data?.users.phone}</h5>
       <h5 style={{color:themeColor.text}}>{t('address')} : {data?.users.location}</h5>
            <h5 style={{color:themeColor.text}}>{t('delivary_type')} : {data?.delivary_type}</h5>
            <h5 style={{color:themeColor.text}}>{t('total with out delivary')} : {state}</h5>
            <h5 style={{color:themeColor.text}}>{t('total')} : {data?.total}</h5>
            <h5 style={{color:themeColor.text}}>{t('order status')} :
            <Select
              native
              onChange={(e)=>UpdateOrder({id:id,status:e.target.value}).then(
                (res)=>swal(
                  t('success'),
                  t('order status updated'),
                  'success'
                )
              
              ).then(()=>window.location.reload())
            
            }
              style={{width:"150px",height:"30px",borderRadius:"5px",marginLeft:"10px"}}
              value={data?.status}
            >
              <option value="pending">{t('pending')}</option>
              <option value="accepted">{t('accepted')}</option>
              <option value="onway">{t('onway')}</option>
              <option value="delivering">{t('delivering')}</option>
              <option value="delivered">{t('delivered')}</option>
              <option value="canceled">{t('canceled')}</option>

            </Select>
            </h5>
            <h5 style={{color:themeColor.text}}>{t('created_at')} : {data?.created_at}</h5>
            <h5 style={{color:themeColor.text}}>{t('updated_at')} : {data?.updated_at}</h5>
            
        <br>
        </br>
        <br>
        </br>
        <hr></hr>
        
            
            
                  <div >
                    
                    <table
                 className="table" id="customers"
                    >
                      <tr>
                        <th style={{border:"1px solid #000"}}>{t('product')}</th>
                        <th style={{border:"1px solid #000"}}>{t('price')}</th>
                        <th style={{border:"1px solid #000"}}>{t('quantity')}</th>
                        <th style={{border:"1px solid #000"}}>{t('total')}</th>
                        <th style={{border:"1px solid #000"}}>{t('image')}</th>
                      </tr>
                      {  
                       data?.orderProducts.map((item,index)=>{  
                     a=a+(item.price * item.quantity)
                 
                          return(
                      <tr>
        
                        <td style={{border:"1px solid #000"}}>{item.productTitle}</td>
                        <td style={{border:"1px solid #000"}}>{item.price}</td>
                        <td style={{border:"1px solid #000"}}>{item.quantity}</td>
                        <td style={{border:"1px solid #000"}}>{item.price * item.quantity}</td>
                    
                        <td style={{border:"1px solid #000"}}><img src={'http://3.223.187.125:8000/uploads/products/'+item.productImage} style={{width:"100px",height:"100px"}}></img></td>
                      </tr>
                           )
              
                          }
                          )
                      }
                      </table>
                  </div>
           
            </div>
            </div>
        </>
      )}
            
      </div>

   
    </>

  
 
  )
}



export default Order;