// import { IconButton } from '@mui/material';
// import { AndroidPushNotificationPreview, ApplePushNotificationPreview, PushNotificationPreview } from "push-notification-preview";

import React, { useEffect, useState } from "react";
import Loading from "../../components/lodaing/Loading";

import {
  Button,
  Switch,
} from "@mui/material";
import { swal } from "../../components/swal";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { BiDetail, BiSave } from "react-icons/bi";
import {  useGetDayQuery, useGetFollowupQuery, useGetFollowupsQuery, useGetGroupsQuery, useGetProductsQuery,
  useUpateProductsMutation, } from "../../app/Selice/FollowupSelice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetMealsQuery } from "../../app/Selice/MealsSelice";



function GroupMeals() {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  //get id from url 
  let { id } = useParams();
  
  console.log(id)
  const { data, isLoading, isFetching, isSuccess } =
 useGetDayQuery(id);

  const themeColor = useSelector((state) => state.theme.value);
  const [loading, isloading] = useState(0);
if(data){
  console.log(data)
}

 var columns =[{
  field: id, headerName: t('id'),   flex:1,
  renderCell: (params) => {
            
        
    
    return <h5  style={{color:themeColor.active}} >{params.row.day}</h5>;
  

  }
 }]


 

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
       
        <div className="" style={{ width: "75vw" }}>
         

          <div className="d-flex justify-content-between">  
            <h3 className="text-center">{t("Meals")}</h3>
            <div className="d-flex justify-content-between">
              <Link to={`/Followup/GroupMeals/${id}/NewMeals`}>
                <Button

                  variant="contained"
                  className="btn btn-success"
                  style={{ color: "white", margin: "10px" }}
                >
                  {t("NewMeals")}
                </Button>
              </Link>
            </div>
          </div>
          <div style={{ height: 400, width: "100%" }}>
          <table className="table" id="customers">
        <thead>
          <tr>

       

            <th>
              <h3
                style={{
            
                  color: "white",
                  float: "center",
                }}
              >
              {t('Number of day')}
              </h3>
            </th>
            <th>
              <h3
                style={{
            
                  color: "white",
                  float: "center",
                }}
              >
          {t('view')}
              </h3>
            </th>
        
           
       
          </tr>
        </thead>
        <tbody>
 {
  data?.data?.map((item,index)=>{
    return (
      <tr>
     
      <td>
        <h5
          style={{
      
  
            float: "center",
          }}
        >
      {
        item?.day
      }
        </h5>
      </td>
      <td  style={{
        // backgroundColor: "white",
        justifyContent: "center",
        float:     localStorage.getItem("lang") == "ar" ? "right" : "left", 
  
      float: "center",
    }}>
        <Link to={`/Followups/${id}/meals/${item?.day}`}>
        <Button
          variant="contained"
          className="btn btn-success"
          style={{ color: "white", margin: "10px",  justifyContent: "center",
     float:     localStorage.getItem("lang") != "ar" ? "right" : "left", 
      
  
          float: "center", }}
        >
          {t("View")}
        </Button>
        </Link>
      
      </td>
      </tr>
    )
  })


 }
    

        </tbody>
 
       

      </table>
      </div>
          </div>
   
      )}
     
    </>
  );


}

export default GroupMeals;