import { ResetTvRounded } from "@mui/icons-material"
import { apiSlice } from "./apiSelice"
export const token = localStorage.getItem('token')
export const UserSelice = apiSlice.injectEndpoints({
    
    endpoints: builder => ({
        
       GetUser:builder.query({
        query:(a)=>'/users?filters=role:nq:trainee&page='+a.page+'&paginate='+a.pageSize,
        providesTags:['co']
   }),
   GetCustomer:builder.query({
    query:(a)=>'/users?filters=role:eq:trainee&page='+a.page+'&paginate='+a.pageSize,
    providesTags:['User']
   }),
   GetOneCustomer:builder.query({
    query:(a)=>'/users/'+a.id,
    providesTags:['User']
   }),
       UpateUser:builder.mutation({
        query:({...rest})=>({
       
              url:`users/${rest.id}`,
              method:'PATCH',
              body:rest,
          
            
        }),
        invalidatesTags: ['User'],

 }),
 PostUser:builder.mutation({
    query:(rest)=>({
        headers:{
            'authorization':`Bearer `+token,
            'Accept': 'application/json"]' ,
            'Content-Type': 'application/json',
          
         },
          url:`users`,
          method:'POST',
          body:{
            "email":rest.email,
            "birthdate":rest.birthdate,
            "name":rest.name,
            "password":rest.password,
            "role":rest.role,
            "phone":rest.phone

          },
       
    }

    ),
    
    invalidatesTags: ['User'],

})
    })
})

export const {
    useGetUserQuery,
    useGetOneCustomerQuery,
    useUpateUserMutation,
    usePostUserMutation,
    useGetCustomerQuery

} = UserSelice