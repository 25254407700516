import { apiSlice } from "./apiSelice"

export const FeedBackSelice = apiSlice.injectEndpoints({
    
    endpoints: builder => ({
        
       GetFeedBack:builder.query({
            query:()=>'/feedbacks',
            providesTags:['FeedBack']
       }),
       UpateFeedBack:builder.mutation({
        query:({...rest})=>({
       
              url:`/feedbacks/${rest.id}`,
              method:'PATCH',
              body:rest,
          
            
        }),
        invalidatesTags: ['FeedBack'],

 }),

    })
})

export const {
    useGetFeedBackQuery,
    useUpateFeedBackMutation

} = FeedBackSelice