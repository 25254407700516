import { apiSlice } from "./apiSelice"

export const TraineeSelice = apiSlice.injectEndpoints({
    
    endpoints: builder => ({
        
       GetTrainee:builder.query({
            query:(pageSize)=>'/trainee?page='+pageSize,
            providesTags:['Trainee']
       }),
       UpateTrainee:builder.mutation({
        query:({...rest})=>({
       
              url:`/trainee/${rest.id}`,
              method:'PATCH',
              body:rest,
          
            
        }),
        invalidatesTags: ['Trainee'],

 }),
 
 PostTrainee:builder.mutation({
    
    query:(formdata)=>({
          url:`/trainee`,
          header:{
            'Content-Type':`multipart/form-data`,
                        
          },
          method:'POST',
          body:formdata,
       
    }

    ),
    
    invalidatesTags: ['Trainee'],

})
    })
})

export const {
    useGetTraineeQuery,
    useUpateTraineeMutation,
    usePostTraineeMutation

} = TraineeSelice