
import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { useGetOrderQuery } from '../../app/Selice/OrderSelice';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

function Orders() {
  const [pageSize, setPageSize] = React.useState(10);
  const themeColor = useSelector((state) => state.theme.value)
   const [page, setPage] = React.useState(1);
  const {
    data, error, isLoading, isFetching, isSuccess
   }=useGetOrderQuery(
    page,pageSize
   )
   console.log(data)
   const {t} = useTranslation();
  const columns = [
    { field: 'id', headerName: 'ID',   flex:1 ,hide:true },
    {
      field: 'user',
      headerName: t('user'),
      flex:1,
      renderCell: (params) => {
            
        
    
          return <h5  style={{color:themeColor.active}} >{params.row.users.name}</h5>;
        
    
        }
    },
    {
      field: 'delivary_type',   
      headerName: t('delivary_type'),     
      flex:1,
    
    },
  
    {
      field: 'total',
      headerName: t('total'),
  
      flex:1,
    
    },
  
 
    

    {
      field: 'status',
      headerName: t('order status'),
     
      flex:1,
    
      renderCell: (params) => {
          
      
  
  if(params.row.status =='pending'){
    return <h6 className={'bg-color'} style={{color:'green',background:'rgba(0, 128, 0, 0.151)'}} >أنتظار</h6>;
  }else if(params.row.status =='onway'){
    return <h6 className={'bg-color'} style={{color:'goldenrod',background:'rgba(189, 189, 3, 0.103'}} >قيد التوصيل</h6>;
  }else if(params.row.status =='accepted'){
    return <h6 className={'bg-color'} style={{color:'rgba(0, 151, 255, 1)',background:'rgba(0, 151, 255, 0.5)'}} >
      مقبول</h6>;
   

  }else if(params.row.status =='delivering'){
    return <h6 className={'bg-color'} style={{color:'rgba(0, 151, 255, 1)',background:'rgba(0, 151, 255, 0.5)'}} >
      قيد التوصيل</h6>;
        }else if(params.row.status =='delivered'){
          return <h6 className={'bg-color'} style={{color:
            'rgba(220, 125, 200, 1)'
            ,background:
          'rgba(220, 125, 200, 0.151)'
          }} >
          تم التوصيل</h6>;

                }else if(params.row.status =='canceled'){
                  return <h6 className={'bg-color'} style={{color:'rgba(255,0,0,1)',background:'rgba(255,0,0,0.5)'}} >
                    ملغي</h6>;
                }
                                                                                
      
  
      },
    },
{
  //view
  field: 'view',
  headerName: t('view'),
  flex:1,
  renderCell: (params) => {
    return <Link 
    to={`/Orders/${params.row.id}`}
    style={{textDecoration:'none'}}
    >

    {t('view')}

    </Link>
  },
    

}
  ];
  return (
    <>

<div style={{height:"80vh",width:"75vw",margin:'20px'}}> 
<DataGrid
      style={{height:"80vh",width:"75vw",margin:'20px',color:themeColor.text}}
        rows={data?data.data:[]}
        columns={columns}
       pageSize={pageSize}
          onPageSizeChange={
            (newPageSize) => setPageSize(newPageSize)
          }
  onPageChange={(newPage) => setPage(page+1)}
          rowsPerPageOptions={[10,25,50,100]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
      </div>

   
    </>

  
 
  )
}



export default Orders;