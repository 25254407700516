// import { IconButton } from '@mui/material';
// import { AndroidPushNotificationPreview, ApplePushNotificationPreview, PushNotificationPreview } from "push-notification-preview";

import React, { useEffect, useState } from "react";
import Loading from "../../components/lodaing/Loading";

import {
  Button,
  Switch,
} from "@mui/material";
import { swal } from "../../components/swal";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { BiDetail, BiSave } from "react-icons/bi";
import {  useGetFollowupQuery, useGetFollowupsQuery, useGetGroupsQuery, useGetProductsQuery,
  useUpateProductsMutation, } from "../../app/Selice/FollowupSelice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetMealsQuery } from "../../app/Selice/MealsSelice";



function Groups() {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  //get id from url 
  let { id } = useParams();
  
  console.log(id)
  const { data, isLoading, isFetching, isSuccess } =
  useGetGroupsQuery(id);

  const themeColor = useSelector((state) => state.theme.value);
  const [loading, isloading] = useState(0);
if(data){
  console.log(data)
}

 var columns =[]
 data?.meta?.columns?.map((item)=>{
  columns.push({
    field: item, headerName: t(item),   flex:1 
  })
 })
 columns?.push({
  
    field: "VIEW",
    headerName: t('view'),
  
    renderCell: (params) => {   
      return <Link style={{color:'#1876D1'}} to={'./'+params.row.id} >عرض</Link>;
  
  },
  });
 

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
       
        <div className="" style={{ width: "75vw" }}>
          <div className="card mb-3">
                 <div className="card-body">
                 <h6 className="d-flex align-items-center mb-3"><i className="material-icons text-info mr-2"/>Days</h6>
                   <div className="row">
                     <div className="col-sm-3">
                       <h6 className="mb-0">New Days</h6>
                     </div>
                   {
                    //link to add new day
                   }
                      <div className="col-sm-9 text-secondary">
                        <Link to={'./newday'}>Add New Day</Link>
                        </div>
                   </div>
                
            
                 </div>
               </div>
        <DataGrid
      style={{height:"80vh",width:"75vw",margin:'20px',color:themeColor.text}}
        rows={data?data.data:[]}
        columns={columns}
       pageSize={pageSize}
          onPageSizeChange={
            (newPageSize) => setPageSize(newPageSize)
          }
  onPageChange={(newPage) => setPage(page+1)}
          rowsPerPageOptions={[10,25,50,100]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
        </div>
   


      )}
     
    </>
  );


}

export default Groups;