export default {
  hallo: "مرحبا",
  DashBoard: "لوحة القيادة",
  Customers: "العملاء",
  Users: "المستخدمين",
  Locations: "المواقع",
  OrderType: "نوع الطلب",
  FeedBack: "ردود الفعل",
  Settings: "النظام",
  Orders: "الطلبات",
  "Total Open FeedBack":
  "إجمالي التعليقات المفتوحة",
"Total finish orders":"إجمالي الطلبات الإنهاء",
  Notifications: "إشعارات",
  Meals: "وجبات",
  "Number of day": "عدد الأيام",
  "Number of meals": "عدد الوجبات",
  "Number of workout": "عدد التمارين",
  "Number of groups": "عدد المجموعات",
  "Number of meals in group": "عدد الوجبات في المجموعة",
  "view": "عرض",
  NewUser: "مستخدم جديد",
  NewOrderType: "نوع طلب جديد",
  NewLocation: "موقع جديد",
  Login: "تسجيل الدخول",
  Email: "البريد الإلكتروني",
  "Password:": "كلمه السر",
  "Total Revenue": "إجمالي الإيرادات",
  "Total sales made today": "إجمالي المبيعات التي تمت اليوم",
  Name: "الاسم",
  Email: "البريد الإلكتروني",
  Password: "كلمه السر",
  NewNotification: "إشعار جديد",
  System: "النظام",
  Lat: "خطوط الطول",
  Lang: "خطوط العرض",
  Point: "نقطة",
  IsDisabled: "معطل",
  Save: "حفظ",
  Price: "السعر",
  Type: "نوع",
  Description: "وصف",
  Status: "الحالة",
  finishedOrders: "الطلبات المنتهية",
  customers: "العملاء",
  delivaries: "التوصيل",
  ordertypes: "أنواع الطلب",
  orders: "الطلبات",
  revenue: "الإيرادات",
  locations: "المواقع",
  feedbacks: "التعليقات",
  Username: "اسم المستخدم",
  Phone: "هاتف",
  Role: "دور",
  Blance: "الرصيد",
  "Last 6 Months (Revenue)": "آخر 6 أشهر (الإيرادات)",
  "Last 6 Months (Orders)": "آخر 6 أشهر (الطلبات)",
  "Tracking ID": "معرف التتبع",
  "from user": "من المستخدم",
  "to user": "إلى المستخدم",
  "from location": "من الموقع",
  "to location": "إلى الموقع",
  "order type": "نوع الطلب",
  "order status": "حالة الطلب",
  "order price": "سعر الطلب",
  "customer to": "العميل إلى",
  "location to": "الموقع إلى",
  "location from": "الموقع من",
  "customer from": "العميل من",
  "order  status": "حالة تعليق الطلب",
};
